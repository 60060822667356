import * as React from "react"

import Footer from "./Footer";
import NavBar, { Pages } from "./NavBar";
import TopBar from "./TopBar";

import "./Layout.scss"
import CookiesBanner from "./CookiesBanner";

const Layout: React.FunctionComponent<{ activePage: Pages}> = ({ activePage, children }) => {
  return (
    <React.Fragment>
      <div className="header-wrapper">
        <NavBar activePage={activePage} />
        <TopBar />
      </div>
      <div className="content-wrapper">{children}</div>
      <Footer />
      <CookiesBanner />
    </React.Fragment>
  );
}

export default Layout
