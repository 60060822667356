import { Link } from "gatsby"
import React from "react"
import { Cookies } from "react-cookie-consent"

import FacebookIcon from "../images/shared/facebook-square-brands.svg"
import InstagramIcon from "../images/shared/instagram-square-brands.svg"
import LinkedInIcon from "../images/shared/linkedin-brands.svg"
import logo from "../images/shared/logo.svg"
import StoreButtons from "./StoreButtons"

import "./Footer.scss"

const Footer: React.FunctionComponent = () => {
  const disableAnalytics = () => {
    const analyticsId = process.env.GATSBY_GOOGLE_ANALYTICS
    const globalProperty = `ga-disable-${analyticsId}`
    // @ts-ignore this is the official Google way to disable tracking. Removing the script is not necessary.
    window[globalProperty] = false
    // prevent the tracking on new sessions. Needs to match the cookie name in gatsby-config
    Cookies.set("gatsby-gdpr-google-analytics", false)
  }
  return (
    <footer className="footer background--electric-blue padding">
      <div className="content-wrapper">
        <div className="introduction-social-download">
          <p className="paragraph--white">
            La Consigne GreenGo est un service 360 d'emballages réutilisables et
            consignés.
          </p>
          <p className="paragraph--white">
            Son service digital est clé en main et s’adapte à tout type de
            structures : restaurant indépendant, cafétéria d’entreprise,
            enseigne de la grande distribution et plateforme de livraison.
          </p>
          <StoreButtons justifyContent="flex-start" size="medium" />
          <nav className="social-links">
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/company/mygreengo/about/"
                  target="_blank"
                  rel="noopener"
                >
                  <img src={LinkedInIcon} alt="Page Linkedin" height="45" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/laconsignegreengo"
                  target="_blank"
                  rel="noopener"
                >
                  <img src={InstagramIcon} alt="Page Instagram" height="45" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/laconsignegreengo/"
                  target="_blank"
                  rel="noopener"
                >
                  <img src={FacebookIcon} alt="Page Facebook" height="45" />
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="footer-logo">
          <Link to="/">
            <img src={logo} alt="logo GreenGo" width="150" />
          </Link>
        </div>
        <nav className="nav nav__footer">
          <div className="main-nav">
            <div>
              <h2>Plan du site</h2>
              <ul>
                <li>
                  <Link to="/">Particuliers</Link>
                </li>
                <li>
                  <Link to="/nous-trouver">Où nous trouver</Link>
                </li>
                <li>
                  <Link to="/professionnels">Professionnels</Link>
                </li>
                <li>
                  <Link to="/notre-equipe">Équipe</Link>
                </li>
                <li>
                  <Link to="/presse">Presse</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                {/* <li>
                  <Link to="/faq">FAQ</Link>
                </li> */}
                <li>
                  <Link
                    className="link button button--color-pink"
                    to="/contact"
                  >
                    Nous contacter
                  </Link>
                </li>
                <li>
                  <a
                    className="link button button--color-pink"
                    href="https://www.welcometothejungle.com/fr/companies/la-consigne-greengo/jobs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Rejoindre l'équipe
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <ul className="legal-links">
        <li>Copyright © La Consigne GreenGo. All Rights Reserved.</li>
        <li>
          <a href="/charte-de-confidentialite.html">
            Conditions générales d'utilisation
          </a>
        </li>
        <li>
          <a href="/charte-de-confidentialite.html">
            Politique de confidentialité
          </a>
        </li>
        <li>
          <a onClick={disableAnalytics}>Révoquer les cookies</a>
        </li>
      </ul>
    </footer>
  )
}

export default Footer
