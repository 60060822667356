import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Img from "gatsby-image"
import "./StoreButtons.scss"

interface IStoreButtonsProps {
  justifyContent?: "flex-start" | "flex-end" | "center"
  size?: "small" | "medium"
}

const StoreButtons: React.FunctionComponent<IStoreButtonsProps> = ({
  justifyContent = "center",
  size = "medium",
}) => {
  const data = useStaticQuery(graphql`
    query GET_STORE_LOGOS {
      appstore: file(name: { eq: "badge-app-store_FR" }) {
        childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      googleplay: file(name: { eq: "badge-google-play_FR" }) {
        childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  const [isAndroid, setIsAndroid] = React.useState(false)
  const [isIOS, setIsIOS] = React.useState(false)
  const [isDesktop, setIsDesktop] = React.useState(false)

  React.useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    )
    if (!mobile) {
      setIsDesktop(true)
      return
    }
    const android = Boolean(userAgent.match(/Android/i))
    if (android) {
      setIsAndroid(true)
      return
    }
    const ios = Boolean(userAgent.match(/iPhone|iPad|iPod/i))
    if (ios) {
      setIsIOS(true)
      return
    }
    setIsDesktop(true)
  }, [])
  return (
    <section
      className={size === "medium" ? "download" : "download download--small"}
      style={{ justifyContent }}
    >
      <a
        href="https://itunes.apple.com/fr/app/greengo/id1440472850?mt=8"
        target="_blank"
        rel="noopener noreferrer"
        title="Télécharger l'app sur l'App Store"
        style={{ display: isDesktop || isIOS ? "initial" : "none" }}
      >
        <Img
          fixed={data.appstore.childImageSharp.fixed}
          alt="Logo de l'App Store"
          loading="eager"
          imgStyle={{ objectFit: "contain" }}
          className="store-logo"
          style={{ height: '100%', width: '100%'}}
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=fr.mygreengo.greengo"
        target="_blank"
        rel="noopener noreferrer"
        title="Télécharger l'app sur le Play Store"
        style={{ display: isDesktop || isAndroid ? "initial" : "none" }}
      >
        <Img
          fixed={data.googleplay.childImageSharp.fixed}
          alt="Logo de Google Play"
          loading="eager"
          imgStyle={{ objectFit: "contain" }}
          className="store-logo"
          style={{ height: '100%', width: '100%'}}
        />
      </a>
    </section>
  )
}

export default StoreButtons
