import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import * as React from "react"

import logo from "../images/shared/logo.svg"

import "./NavBar.scss"

const pages = [
  "index",
  "notre-equipe",
  "professionnels",
  "nous-trouver",
  "on-recrute",
  "contact",
  "blog",
  "faq",
  "presse",
] as const

export type Pages = typeof pages[number]

interface NavBarProps {
  activePage: Pages
}

const NavBar: React.FunctionComponent<NavBarProps> = ({ activePage }) => {
  const data = useStaticQuery(graphql`
    query GET_PARIS_SEMBALLE_LOGO {
      file(name: { eq: "logo-paris-semballe" }) {
        childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <header className="header background--white">
      <div className="header__content-wrapper">
        <Link to="/">
          <img src={logo} alt="logo GreenGo" className="logo" />
        </Link>
        <nav role="navigation" className="header__nav nav">
          <div id="desktop">
            {generateNavLink("desktop", activePage)}
            <a
              href="/contact"
              className="contact-btn link button button--color-pink"
            >
              Nous contacter
            </a>
          </div>

          <div id="mobile">
            <a
              href="/contact"
              className="contact-btn link button button--color-pink"
            >
              Nous contacter
            </a>
            <div id="menuToggle">
              <input type="checkbox" />
              <span />
              <span />
              <span />
              {generateNavLink("mobile", activePage)}
            </div>
          </div>
        </nav>
        <Link
          to="/blog/paris-semballe-le-projet/"
          target="_blank"
          rel="noopener"
          className="hide-handheld"
        >
          <Img
            loading="eager"
            fadeIn={false}
            fixed={data.file.childImageSharp.fixed}
          />
        </Link>
      </div>
    </header>
  )
}

const generateNavLink = (platform: "mobile" | "desktop", activePage: Pages) => {
  const desktopLinks: [Pages, string][] = [
    ["nous-trouver", "Où nous trouver"],
    ["professionnels", "Professionnels"],
    ["notre-equipe", "Équipe"],
    ["presse", "Presse"],
    ["blog", "Blog"],
  ]
  const mobileLinks: [Pages, string][] = [...desktopLinks]
  return (
    <ul id={platform === "mobile" ? "menu" : ""} className="nav__items">
      {(platform === "mobile" ? mobileLinks : desktopLinks).map(v =>
        getNavLink(v[0], v[1], activePage == v[0])
      )}
    </ul>
  )
}

const getNavLink = (link: Pages, content: string, active: boolean) => (
  <li key={link} className={`nav__item ${active ? "active" : ""}`}>
    <Link
      to={link === "index" ? "/" : `/${link}`}
      className={`link link--color-electric-blue nav__link ${link} `}
    >
      {content}
    </Link>
  </li>
)

export default NavBar
