import React from "react"
import CookieConsent from "react-cookie-consent"
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import "./CookiesBanner.scss"

const CookiesBanner: React.FunctionComponent = () => {
  const location = useLocation()
  const initializeAnalytics = () => initializeAndTrack(location)
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accepter"
      enableDeclineButton
      declineButtonText="Refuser"
      cookieName="gatsby-gdpr-google-analytics"
      style={{
        alignItems: "center",
        background: "#2B373B",
        justifyContent: "center",
        left: "50%",
        transform: "translateX(-50%)",
        width: "fit-content",
        fontFamily: "Helvetica, sans-serif",
      }}
      contentStyle={{
        maxWidth: 500,
      }}
      buttonStyle={{
        background: "var(--purply-pink)",
        color: "var(--white)",
        fontSize: "13px",
        fontFamily: "Helvetica, sans-serif",
      }}
      declineButtonStyle={{
        background: "var(--purply-pink)",
        color: "var(--white)",
      }}
      expires={365}
      onAccept={initializeAnalytics}
    >
      Ce site utilise des cookies pour recueillir et analyser des informations
      relatives à sa performance et son usage.{" "}
      <a href="/charte-de-confidentialite">En savoir plus.</a>
    </CookieConsent>
  )
}

export default CookiesBanner;