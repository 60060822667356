import { graphql, navigate, useStaticQuery } from "gatsby"
import * as React from "react"

import CloseIcon from "../images/shared/close.svg"

import "./TopBar.scss"

const TopBar: React.FunctionComponent = () => {
  const data = useStaticQuery<{
    mdx: {
      frontmatter: { show: boolean; sentence: string; link: string | undefined }
    }
  }>(graphql`
    {
      mdx(slug: { eq: "announcement_bar" }) {
        frontmatter {
          show
          sentence
          link
        }
      }
    }
  `)
  const { show: isTopBarActive, sentence, link } = data.mdx.frontmatter
  if (!isTopBarActive) return null
  const getTopBarStatusFromSession = () =>
    sessionStorage.getItem("hide-topbar") === "true"
  const [isClosed, setIsClosed] = React.useState<boolean>(true)
  React.useEffect(() => {
    /* getting the status of TopBar with useEffect, so we avoid the quick TopBar flashing if
    the method is called directly from useState initial state when hide-topbar value is 'true' */
    setIsClosed(getTopBarStatusFromSession())
  }, [])
  if (isClosed) return null
  const handleClose = () => {
    sessionStorage.setItem("hide-topbar", "true")
    setIsClosed(true)
  }
  const handleClick = (target: string) => {
    handleClose()
    // if we use Gatsby Link `navigate` method, there might be a long delay before the page change
    window.location.pathname = target;
  }
  return (
    <div className="top-bar background--electric-blue">
      <div className="container">
        <div className="placeholder" />
        <p {...(link ? { className: "pointer-on-hover", onClick: () => handleClick(link) } : {})}>{sentence}</p>
        <img
          className="close-icon"
          onClick={handleClose}
          src={CloseIcon}
          width="15"
          height="15"
        />
      </div>
    </div>
  )
}

export default TopBar
